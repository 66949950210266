import React, { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

import Header from '../components/layouts/header'
import { Seo } from '@/components/seo'
import { Button } from '@/components/ui/button'
import Link from 'next/link'

function NotFoundPage() {
  const t = useTranslations('NotFound')
  const router = useRouter()
  const currentPath = router.asPath

  useEffect(() => {
    Sentry.captureMessage(`404 - Page Not Found: ${currentPath}`, 'warning')
  }, [currentPath])

  return (
    <div className="container">
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        path="/404"
      />
      <Header title={t('headerTitle')} />
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-200px)]">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">{t('title')}</h1>
        <p className="text-xl text-gray-600 mb-8">{t('description')}</p>
        <Link href="/">
          <Button>{t('homeButton')}</Button>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: (await import(`../messages/${locale}.json`)).default
    }
  }
}
